import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

let router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: '/Login',
      component: () => import('@/pages/Login.vue'),
      name: 'login',
      meta: {
        title: "山大文赢",
      }
    },
    {
      path: '/CodeYzm',
      component: () => import('@/pages/CodeYzm.vue'),
      name: 'codeYzm',
      meta: {
        title: "设置密码"
      }
    },
    {
      path: '/SetPassword',
      component: () => import('@/pages/SetPassword.vue'),
      name: 'setPassword',
      meta: {
        title: "设置密码"
      }
    },
    {
      path: '/Password',
      component: () => import('@/pages/Password.vue'),
      name: 'password',
      meta: {
        title: "验证密码"
      }
    },
    {
      path: '/GoLogin',
      component: () => import('@/pages/GoLogin.vue'),
      name: 'goLogin',
      meta: {
        title: "设置成功"
      }
    },
    {
      path: '/Home',
      component: () => import('@/pages/Home.vue'),
      name: 'home',
      meta: {
        title: "首页",
        requireAuth: true
      }
    },
    {
      path: '/Feedback',
      component: () => import('@/pages/Feedback.vue'),
      name: 'feedback',
      meta: {
        title: "用户反馈",
        requireAuth: true
      }
    },
    {
      path: '/SetInfo',
      component: () => import('@/pages/SetInfo.vue'),
      name: 'setInfo',
      meta: {
        title: "核对信息",
        requireAuth: true
      }
    },
    {
      path: '/UserInfo',
      component: () => import('@/pages/UserInfo.vue'),
      name: 'userInfo',
      meta: {
        title: "个人信息",
        requireAuth: true
      }
    },
    {
      path: '/Material',
      component: () => import('@/pages/Material.vue'),
      name: 'material',
      meta: {
        title: "资料状态",
        requireAuth: true
      }
    },
    {
      path: '/ReceiveLog',
      component: () => import('@/pages/ReceiveLog.vue'),
      name: 'receiveLog',
      meta: {
        title: "领取记录",
        requireAuth: true
      }
    },
    {
      path: '/CreatePlan',
      component: () => import('@/pages/CreatePlan.vue'),
      name: 'createPlan',
      meta: {
        title: "正在领取",
        requireAuth: true
      }
    },
    {
      path: '/EnterFace',
      component: () => import('@/pages/EnterFace.vue'),
      name: 'enterFace',
      meta: {
        title: "人脸录入",
        requireAuth: true
      }
    },
    {
      path: '/Course',
      component: () => import('@/pages/Course.vue'),
      name: 'course',
      meta: {
        title: "资料领取",
        requireAuth: true
      }
    },
    {
      path: '/Hostel',
      component: () => import('@/pages/Hostel.vue'),
      name: 'hostel',
      meta: {
        title: "在线选床",
        requireAuth: true
      }
    },
    {
      path: '/HostelList',
      component: () => import('@/pages/HostelList.vue'),
      name: 'hostelList',
      meta: {
        title: "选择宿舍",
        requireAuth: true
      }
    },
    {
      path: '/Prompt',
      component: () => import('@/pages/Prompt.vue'),
      name: 'prompt',
      meta: {
        title: "入住须知",
        requireAuth: true
      }
    },
    {
      path: '/Order',
      component: () => import('@/pages/Order.vue'),
      name: 'order',
      meta: {
        title: "订单详情",
        requireAuth: true
      }
    },
    {
      path: '/PayComplete',
      component: () => import('@/pages/PayComplete.vue'),
      name: 'payComplete',
      meta: {
        title: "支付成功",
        requireAuth: true
      }
    },
    {
      path: '/PayFail',
      component: () => import('@/pages/PayFail.vue'),
      name: 'PayFail',
      meta: {
        title: "支付失败",
        requireAuth: true
      }
    },
    {
      path: '/',
      redirect: '/Home',
      meta: {
        title: "山大文赢",
        requireAuth: true
      }
    },
    
  ]
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }

  let token = sessionStorage.getItem('s_token')
  let openId = sessionStorage.getItem('s_openId')

  if (to.path !== '/Login') {
    if (to.meta.requireAuth) {
      if (token && openId) {
      // if (token) {
        next()
      } else {
        next("/Login")
      }
    }
  }
  next()
})

export default router