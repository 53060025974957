import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import 'reset-css'
import "@/assets/iconfont/iconfont.css";
import 'lib-flexible/flexible'
import 'vant/lib/index.css'
import '@/vantui'

Vue.config.productionTip = false
window.addEventListener('resize', function () {
  if (document.activeElement.tagName === 'INPUT' || document.activeElement.tagName === 'TEXTAREA') {
    window.setTimeout(function () {
      document.activeElement.scrollIntoViewIfNeeded()
    }, 0)
  }
})

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
