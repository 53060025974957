import Vue from 'vue';
import {Overlay,Loading,ImagePreview,Uploader,Empty,ActionSheet,Sku,Tabs, Tab, Area, PasswordInput, NumberKeyboard, Popup, Picker, RadioGroup, Radio, Dialog, Toast, NavBar, Divider, Icon, Button, List, Cell, Search, Form, Field, Col, Row, Image as VanImage } from 'vant';

Vue.use(Toast);
Vue.use(PasswordInput);
Vue.use(NumberKeyboard);
Vue.use(Picker);
Vue.use(Popup);
Vue.use(Button);
Vue.use(Form);
Vue.use(Field);
Vue.use(Area);
Vue.use(Icon);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Sku);
Vue.use(ActionSheet);
Vue.use(Empty);
Vue.use(Dialog);
Vue.use(Uploader);
Vue.use(List);
Vue.use(Search);
Vue.use(ImagePreview);
Vue.use(Loading);
Vue.use(Overlay);



// Vue.use(List);
Vue.use(Cell);
// Vue.use(Search);

// Vue.use(Col);
// Vue.use(Row);
// Vue.use(VanImage);
// Vue.use(Icon);
// Vue.use(Divider);
// Vue.use(NavBar);

// Vue.use(Dialog);
// Vue.use(Radio);
// Vue.use(RadioGroup);
